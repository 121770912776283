export default function (type, element, content, children) {
  const widthRegExp = /width="[0-9]+"/
  const heightRegExp = /height="[0-9]+"/

  switch (type) {
    case 'image':
      if (element.linkTo) {
        if (element.linkTo.link_type === 'Document') {
          return `<img src="${
            element.url
          }" class="w-full h-auto rounded-lg" alt="${
            element.alt || ''
          }" copyright="${element.copyright || ''}">`
        }
      }
      return `<img src="${element.url}" class="w-full h-auto rounded-lg" alt="${
        element.alt || ''
      }" copyright="${element.copyright || ''}">`
    case 'embed':
      return element.oembed.html
        .replace(widthRegExp, '')
        .replace(heightRegExp, '')
        .replace('feature=oembed', 'feature=oembed&rel=0')
        .replace('iframe', 'iframe class="aspect-video w-full mx-auto my-4"')
    default:
      return ''
  }
}
